import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import styled from "styled-components";

import Seo from "../seo";
import { Container, Wrapper, Row, Box } from "../util";
import { CallToAction, Breadcrumb } from "../site";
import Layout from "../layout";


const CustomerDetail = styled.div`
  padding-bottom: 20px;
  border-bottom: solid 1px ${(props) => props.theme.colours.grey};

  > ul {
    margin-top: 20px;
  }
`;

export const CustomerStory = (props) => {
    const customer = props.customer;

    let banner, logo, additionalImage, content;

    if (props.isTemplate) {
        additionalImage = (
            customer.additionalImage && (<GatsbyImage
                alt={customer.name + ` | PayHero Customer`}
                image={customer.additionalImage.gatsbyImageData}
            />)
        )
        logo = (
            <GatsbyImage
                alt={customer.name + ` | PayHero Customer`}
                image={customer.logo.gatsbyImageData}
                css={{ maxWidth: "200px" }}
            />
        )
        banner = (
            <GatsbyImage
                alt={`${customer.name} | PayHero Customer`}
                image={customer.banner.gatsbyImageData}
                className="-rounded -addShadow"
            />
        );
        content = (renderRichText(customer.content, props.contentOptions));
    } else {
        additionalImage = (
            customer.additionalImage && (
                <img
                    alt={customer.name + ` | PayHero Customer`}
                    src={customer.additionalImage.fields.file.url}
                />
            )
        );
        logo = (
            customer.logo && (<img
                alt={customer.name + ` | PayHero Customer`}
                src={customer.logo.fields.file.url}
                css={{ maxWidth: "200px" }}
            />)
        );
        banner = (
            customer.banner && (<img
                alt={`${customer.name} | PayHero Customer`}
                src={customer.banner.fields.file.url}
                className="-rounded -addShadow -fullWidth"
            />));
        content = documentToReactComponents(customer.content, props.contentOptions);
    }

    return (
        <Layout>
            <Seo
                title={`${customer.name} | PayHero Payroll Reviews & Case Studies`}
                description=""
                pathname={props.location.pathname}
            />
            <Breadcrumb
                parentPage="Customer Stories"
                parentPageLink="/case-studies"
                currentPage={customer.name}
            />
            <Container>
                <Wrapper stackGap={50} css={{ paddingBottom: "100px" }}>
                    {banner}
                    <Row stackGap={70}>
                        <Box size={70} stackGap={50} className="font-bump">
                            {logo}
                            <Box stackGap={12}>
                                <h1>{customer.name}</h1>
                                <h4 css={{ color: "#999" }}>{customer.industry}</h4>
                            </Box>
                            <div className="font-bump">
                                {content}
                            </div>
                        </Box>
                        <Box size={30} stackGap={20}>
                            <CustomerDetail>
                                <strong>Company:</strong> {customer.name}
                            </CustomerDetail>
                            <CustomerDetail>
                                <strong>Website:</strong>{" "}
                                {customer.website && (
                                    <a href={customer.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {customer.website.replace(/https:\/|http:|\/|www./g, "")}
                                    </a>)}
                            </CustomerDetail>
                            <CustomerDetail>
                                <strong>Industry:</strong> {customer.industry}
                            </CustomerDetail>
                            <CustomerDetail>
                                <strong>Customer since:</strong> {customer.customerSince}
                            </CustomerDetail>
                            <CustomerDetail>
                                <strong>Team size:</strong> {customer.teamSize}
                            </CustomerDetail>
                            <CustomerDetail>
                                <strong>Why PayHero?</strong>
                                <ul>
                                    {customer.benefits && customer.benefits.map((benefit, i) => (
                                        <li key={i}>{benefit}</li>
                                    ))}
                                </ul>
                            </CustomerDetail>
                            {additionalImage}
                        </Box>
                    </Row>
                </Wrapper>
            </Container>
            <CallToAction
                description={`Join ${customer.name} and other Kiwi companies doing payroll better.`}
            />
        </Layout>
    );
};
